import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import BgImage from "../../assets/images/background/bg10.jpg"
import { ToastContainer, toast } from "react-toastify"
import swal from "sweetalert"
import PageBanner from "../../layouts/PageBanner"
import { IMAGES } from "../../constant/theme"
import { Label, FormFeedback, Input, Form, Col } from "reactstrap"
import { VolunteerForm } from "../../features/FormDataStore"
import { useSelector } from "react-redux"
const Volunteers = () => {
  const dispatch = useDispatch()
  const nav = useNavigate()
  const { SuccessVolunteer } = useSelector((state) => state.FormDataReducer)
  const preferredContactArray = [
    { label: "Email", value: "Email" },
    { label: "Phone", value: "Phone" },
    { label: "Whatsapp", value: "Whatsapp" },
    { label: "Any of the above", value: "Any of the above" },
  ]
  const volunteerforArray = [
    { label: "Pongal", value: "Pongal" },
    { label: "Deepavali", value: "Deepavali" },
    { label: "New Year", value: "New Year" },
    { label: "Picnic", value: "Picnic" },
    { label: "Any of the above", value: "Any of the above" },
  ]

  useEffect(() => {
    if (SuccessVolunteer) {
      validation.resetForm()
      swal(
        "Form submitted!",
        "Volunteer Registration is successfully submitted",
        "success"
      )
      nav("/")
    }
  }, [SuccessVolunteer])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      email: "",
      phone_no: "",
      preference_volunteer: [],
      preferred_cnt_chnl: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      phone_no: Yup.string()
        .matches(
          /^[0-9+\-\(\) ]{8,20}$/,
          "Invalid phone number,Example :+91 7289789898 ,+1 (201)78987678 (Enter country code and Mobile number)"
        )
        .required("Phone Number is required"),

      email: Yup.string()
        .email("Invalid mail Formate")
        .required("Email is required"),
      preference_volunteer: Yup.array()
        .min(1, "Select at least one contact channel")
        .of(
          Yup.string().oneOf(
            ["Pongal", "Deepavali", "New Year", "Any of the above"],
            "Invalid contact channel"
          )
        )
        .required("Select at least one contact channel"),
      preferred_cnt_chnl: Yup.array()
        .min(1, "Select at least one contact channel")
        .of(
          Yup.string().oneOf(
            ["Email", "Phone", "Whatsapp", "Picnic", "Any of the above"],
            "Invalid contact channel"
          )
        )
        .required("Select at least one contact channel"),
    }),
    onSubmit: (values, actions) => {
      console.log(values)
      dispatch(VolunteerForm(values))
    },
  })

  const handleKeyDown = (event) => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    if (
      !/^\d$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault()
    }
  }
  const handleKeyDownmobile = (event) => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    if (
      !/^[0-9+()\-\s]*$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault()
    }
  }
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="Volunteers" />
        <section
          className="content-inner-1 gradient-white  section-wrapper5"
          style={{
            // backgroundImage: "url(" + BgImage + ")",
            paddingBottom: 0,
            backgroundPosition: "center",
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-md-9 col-sm-12">
                <div className="form-wrapper style-3">
                  <div className="contact-area">
                    <Form
                      onSubmit={(event) => {
                        event.preventDefault()
                        validation.handleSubmit()
                      }}
                    >
                      <div className="dzFormMsg"></div>
                      <input
                        type="hidden"
                        className="form-control"
                        name="dzToDo"
                        value="Contact"
                      />
                      <input
                        type="hidden"
                        className="form-control"
                        name="reCaptchaEnable"
                        value="0"
                      />

                      <div className="row sp15">
                        <div className="mb-3 col-12">
                          <Label htmlFor="name">
                            Name<span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name"
                            className={`form-control`}
                            placeholder="Enter Your name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3 col-12">
                          <Label htmlFor="email">
                            Email<span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="email"
                            className={`form-control`}
                            placeholder="Enter Your Email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3 col-12">
                          <Label htmlFor="phone_no">
                            Phone No<span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="phone_no"
                            className="form-control  "
                            placeholder="Enter Your Phone Number"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone_no || ""}
                            onKeyDown={handleKeyDownmobile}
                            invalid={
                              validation.touched.phone_no &&
                              validation.errors.phone_no
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone_no &&
                          validation.errors.phone_no ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone_no}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3 col-12 d-flex flex-column">
                          <Label htmlFor="preferred_cnt_chnl">
                            Preferred contact channel
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="d-flex flex-column flex-lg-row  flex-sm-column ">
                            {preferredContactArray.map((option, idx) => (
                              <label key={idx}>
                                <Input
                                  type="checkbox"
                                  className="form-check-input mx-3"
                                  name="preferred_cnt_chnl"
                                  value={option.value}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  checked={validation.values.preferred_cnt_chnl.includes(
                                    option.value
                                  )}
                                />
                                {option.label}
                              </label>
                            ))}
                          </div>
                          {validation.touched.preferred_cnt_chnl &&
                          validation.errors.preferred_cnt_chnl ? (
                            <div style={{ fontSize: "12px", color: "red" }}>
                              {validation.errors.preferred_cnt_chnl}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3 col-12 d-flex flex-column">
                          <Label htmlFor="preference_volunteer">
                            Your preference on event details for which you can
                            volunteer?
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="d-flex flex-column flex-lg-row  flex-sm-column ">
                            {volunteerforArray.map((option, idx) => (
                              <label key={idx}>
                                <Input
                                  type="checkbox"
                                  className="form-check-input mx-3"
                                  name="preference_volunteer"
                                  value={option.value}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  checked={validation.values.preference_volunteer.includes(
                                    option.value
                                  )}
                                />
                                {option.label}
                              </label>
                            ))}
                          </div>
                          {validation.touched.preference_volunteer &&
                          validation.errors.preference_volunteer ? (
                            <div style={{ fontSize: "12px", color: "red" }}>
                              {validation.errors.preference_volunteer}
                            </div>
                          ) : null}
                        </div>

                        <Col className="col-12 d-flex mt-3">
                          <div className=" text-end mt-3 col-12">
                            <button
                              type="submit"
                              className="btn btn-success px-5 "
                            >
                              {"Submit"}
                            </button>
                          </div>
                        </Col>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner-2 text-dark mb-5">
          <div className="container d-flex flex-wrap">
            <div className="col-lg-6">
              <h1 className="h1 fw-bold text-primary sub-title"> GIVE TIME</h1>
              <p>
                The San Antonio Tamil Sangam(SATS) volunteer opportunities range
                to oragnize various event like (Pongal, Deepavali, Tamil New
                Year..etc), sports event like(Cricket, Kids/Ladies tournament),
                Summer Picnic, Health Fair ..etc. Whether you’re an individual,
                group, or company there are many opportunities to volunteer.
              </p>
              {/* <p>
                Click here{" "}
                <a
                  target="_blank"
                  className="link-warning fw-bold"
                  rel="noreferrer"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSc8glufCkfjcJCK02-hDS37RGTR3O4WNvezu4OzCxseoJVWiw/viewform"
                >
                  To Signup
                </a>
              </p> */}
              <p> For Questions Regarding Volunteering, Please Contact:</p>
              <ul
                className="fw-bolder"
                style={{
                  lineHeight: "1.5",
                }}
              >
                <li>MR. Durai Natarajan</li>
                <li>+1 (210) 332-3260</li>
                <li> sats@satamilsangam.org</li>
              </ul>
            </div>
            <div className="col-lg-5">
              <img src={IMAGES.volunteers} alt="" />
            </div>
          </div>
        </section>
      </div>
      <ToastContainer style={{ zIndex: "10999" }} />
    </>
  )
}

export default Volunteers
