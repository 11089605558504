import React from "react"
import { Link } from "react-router-dom"

import PageBanner from "../../layouts/PageBanner"

//images
import bg from "../../assets/images/banner/bnr4.jpg"
import shape2 from "../../assets/images/pattern/shape2.png"
import shape3 from "../../assets/images/pattern/shape3.png"
import shape5 from "../../assets/images/pattern/shape5.png"
import shape1 from "../../assets/images/pattern/shape1.png"
import shape6 from "../../assets/images/pattern/shape6.png"
import { Table } from "reactstrap"
import YouthWingBlock from "../../components/YouthWingBlock"
import YouthClubBlock from "../../components/YouthClubBlock"

// Members
import chandrabose from "../../assets/images/SATS club/youth/Members/Chandrabose Senthilkumar.jpg"
import Amiitesh from "../../assets/images/SATS club/youth/Members/Amiithesh Dhanuskodi.jpg"
import Aventhi from "../../assets/images/SATS club/youth/Members/Aventhi Dharani.jpg"
import Saadhana from "../../assets/images/SATS club/youth/Members/Saadhana Vijaybabu.jpg"
import Seema from "../../assets/images/SATS club/youth/Members/Seema Srinivasan.jpg"
import Tanya from "../../assets/images/SATS club/youth/Members/Tanya Suresh.jpg"
import Joel from "../../assets/images/SATS club/youth/Members/Joel Nitin Alfred Beryl Mathavan.PNG"
import Vishal from "../../assets/images/SATS club/youth/Members/Vishal Vijayakumar.jpg"

const YouthWing = () => {
  const YouthWingMembers = [
    {
      name: "Vishal Vijaykumar",
      image: Vishal,
      Role: "President",
    },
    {
      name: "Tanya Suresh",
      image: Tanya,
      Role: "Secretary",
    },
    {
      name: "Saadhana Vijaybabu",
      image: Saadhana,
      Role: "Executive Team Member",
    },
    {
      name: "Aventhi Dharani",
      image: Aventhi,
      Role: "Executive Team Member",
    },
    {
      name: "Seema Srinivasan",
      image: Seema,
      Role: "Executive Team Member",
    },
    {
      name: "Amiithesh Dhanuskodi",
      image: Amiitesh,
      Role: "Executive Team Member",
    },
    {
      name: "Joel Nitin Alfred Beryl Mathavan",
      image: Joel,
      Role: "Executive Team Member",
    },
    {
      name: "Chandrabose Senthilkumar",
      image: chandrabose,
      Role: "Executive Team Member",
    },
  ]
  return (
    <>
      <div className="page-content bg-white text-dark">
        <PageBanner maintitle="Home" pagetitle="Youth Wing" />

        <section
          className="content-inner-2 bg-light section-pattren1"
          style={{ paddingTop: 30 }}
        >
          <div className="container">
            <div className="row about-bx3 align-items-center">
              <YouthWingBlock />
            </div>
          </div>
          <img src={shape2} className="shape-6 move-2" alt="shape" />
          <img src={shape3} className="shape-5 move-1" alt="shape" />
          <img src={shape5} className="shape-1 rotating" alt="shape" />
          <img src={shape1} className="shape-3 move-1" alt="shape" />
          <img src={shape6} className="shape-4 rotating" alt="shape" />
          <img src={shape6} className="shape-2 rotating" alt="shape" />
        </section>
        <section
          className="content-inner-2 section-pattren1"
          style={{ paddingTop: 50 }}
        >
          <div className="container">
            <div className="row about-bx3 align-items-center">
              <YouthClubBlock />
            </div>
          </div>

          <img src={shape2} className="shape-6 move-2" alt="shape" />
          <img src={shape3} className="shape-5 move-1" alt="shape" />
          <img src={shape5} className="shape-1 rotating" alt="shape" />
          <img src={shape1} className="shape-3 move-1" alt="shape" />
          <img src={shape6} className="shape-4 rotating" alt="shape" />
          <img src={shape6} className="shape-2 rotating" alt="shape" />
        </section>
        <section
          className="content-inner-2 section-pattren1"
          style={{ paddingTop: 50 }}
        >
          <div className="container">
            <div className="row">
              <h2 className="text-primary text-center border-bottom pb-2">
                Youth Wing Team
              </h2>
              <Table hover>
                <thead>
                  <tr>
                    <th>Profile</th>
                    <th>Name</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                  {YouthWingMembers.map((item, ind) => (
                    <tr>
                      <td>
                        <div style={{ height: "100px", width: "100px" }}>
                          <img src={item.image} style={{ width: "100%" }}></img>
                        </div>
                      </td>
                      <td className="align-self-center">{item.name}</td>
                      <td>{item.Role}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>

          <img src={shape2} className="shape-6 move-2" alt="shape" />
          <img src={shape3} className="shape-5 move-1" alt="shape" />
          <img src={shape5} className="shape-1 rotating" alt="shape" />
          <img src={shape1} className="shape-3 move-1" alt="shape" />
          <img src={shape6} className="shape-4 rotating" alt="shape" />
          <img src={shape6} className="shape-2 rotating" alt="shape" />
        </section>
      </div>
    </>
  )
}

export default YouthWing
