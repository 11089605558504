import React, { useRef, useEffect } from "react"
import emailjs from "@emailjs/browser"
import swal from "sweetalert"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PageBanner from "../layouts/PageBanner"
import PartnershipSlider from "../components/Home/PartnershipSlider"
import UpdateBlog from "../components/Home/UpdateBlog"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap"
// import bg from "../assets/images/banner/bnr1.jpg"
import shape1 from "../assets/images/pattern/shape1.png"
import shape3 from "../assets/images/pattern/shape3.png"
import shape5 from "../assets/images/pattern/shape5.png"
import shape6 from "../assets/images/pattern/shape6.png"
import shape7 from "../assets/images/pattern/shape7.png"
import { phoneNumber } from "../helpers/Api_help"
import * as Yup from "yup"
import { useFormik } from "formik"
import { contactUsForm } from "../features/FormDataStore"
const cardBlog = [
  {
    title: "Trusted Partner",
    subtitle: phoneNumber,
    icon: "flaticon-phone-call-1",
  },
  { title: "Mail", subtitle: "sats@satamilsangam.org", icon: "flaticon-email" },
  {
    title: "Our Address",
    subtitle: "12203 Serenity Farm, San Antonio, TX 78249",
    icon: "flaticon-pin",
  },
]

const ContactUs = () => {
  const dispatch = useDispatch()
  const nav = useNavigate()
  const { SuccesscontactUs } = useSelector((state) => state.FormDataReducer)
  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault()
    console.log(e.target.value)
  }
  useEffect(() => {
    if (SuccesscontactUs) {
      validation.resetForm()
      swal("Form submitted!", "Email send successfuly submitted", "success")
      nav("/")
    }
  }, [SuccesscontactUs])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",

      email: "",

      phoneNumber: "",
      message: "",
    },
    onSubmit: (values, actions) => {
      console.log(values)
      dispatch(contactUsForm(values))
    },
  })
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner
          maintitle="Contact"
          pagetitle="Contact Us"
          // background={bg}
        />
        <section className="content-inner-1 bg-light section-pattren1">
          <div className="container">
            <div className="row justify-content-center">
              {cardBlog.map((item, ind) => (
                <div className="col-lg-4 col-md-6 m-b20" key={ind}>
                  <div className="icon-bx-wraper box-hover style-3">
                    <div className="icon-lg">
                      {/* <Link to={"/services-details"} className="icon-cell">
                        <i className={item.icon}></i>
                      </Link> */}
                      <Link to={"#!"} className="icon-cell">
                        <i className={item.icon}></i>
                      </Link>
                    </div>
                    <div className="icon-content">
                      <h5 className="dz-tilte m-b5 text-capitalize">
                        {item.title}
                      </h5>
                      <span>{item.subtitle}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <img src={shape1} className="shape-1 move-1" alt="shape" />
          <img src={shape3} className="shape-3 move-1" alt="shape" />
          <img src={shape5} className="shape-4 rotating" alt="shape" />
          <img src={shape6} className="shape-5 rotating" alt="shape" />
        </section>
        <section className="content-inner map-wrapper1">
          <div className="container-fluid">
            <div className="map-iframe style-1">
              {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3470.5822761235204!2d-98.621666!3d29.557657!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865c66537e3e8247%3A0xab335a8049d6e9ff!2s12203%20Serenity%20Farm%2C%20San%20Antonio%2C%20TX%2078249!5e0!3m2!1sen!2sus!4v1695721103348!5m2!1sen!2sus"
                width="600"
                height="450"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3470.5822761235204!2d-98.621666!3d29.557657!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865c66537e3e8247%3A0xab335a8049d6e9ff!2s12203%20Serenity%20Farm%2C%20San%20Antonio%2C%20TX%2078249!5e0!3m2!1sen!2sus!4v1695721103348!5m2!1sen!2sus"
                width="800"
                height="1000"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              {/* <iframe
                src="https://maps.app.goo.gl/zssVga7dRiLdpqaa9"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
              ></iframe> */}
            </div>
            <div className="row justify-content-end">
              <div className="col-xl-6 col-lg-8 col-sm-12">
                <div className="contact-info form-wrapper style-1">
                  <h2 className="title">Write us a message</h2>
                  <div className="contact-area">
                    <Form
                      className="dz-form dzForm contact-bx"
                      onSubmit={(event) => {
                        event.preventDefault()
                        validation.handleSubmit()
                      }}
                    >
                      <div className="dzFormMsg"></div>
                      <input
                        type="hidden"
                        className="form-control"
                        name="dzToDo"
                        value="Contact"
                      />
                      <div className="row sp15">
                        <div className="col-md-6">
                          <label className="form-label">First Name</label>
                          <div className="input-group">
                            <input
                              name="firstName"
                              required
                              type="text"
                              className="form-control"
                              placeholder="Enter Your First Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">Last Name</label>
                          <div className="input-group">
                            <input
                              name="lastName"
                              required
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Last Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">Email address</label>
                          <div className="input-group">
                            <input
                              name="email"
                              required
                              type="text"
                              className="form-control"
                              placeholder="Enter Your email id"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">Phone Number</label>
                          <div className="input-group">
                            <input
                              name="phoneNumber"
                              required
                              type="number"
                              className="form-control"
                              placeholder="Enter Your Phone Number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <label className="form-label">Message</label>
                          <div className="input-group">
                            <textarea
                              name="message"
                              rows="4"
                              required
                              className="form-control"
                              placeholder="Dear Sir/Madam"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="input-recaptcha">
                            {/* <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                        <input className="form-control d-none" style={{display:"none"}} data-recaptcha="true" required data-error="Please complete the Captcha" /> */}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button
                            name="submit"
                            type="submit"
                            value="Submit"
                            className="btn btn-primary"
                          >
                            Submit Now
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src={shape5} className="shape-2 move-2" alt="shape" />
          <img src={shape7} className="shape-1 move-2" alt="shape" />
          <img src={shape6} className="shape-3 move-2" alt="shape" />
        </section>
        {/* <section className="clients-wrapper p-0">
          <div className="container">
            <div className="section-head text-center">
              <h3 className="title">Our Partnership</h3>
            </div>
            <PartnershipSlider />
          </div>
        </section> */}
        {/* <div className="call-action style-1 content-inner-1">
          <div className="container">
            <UpdateBlog />
          </div>
        </div> */}
      </div>
    </>
  )
}

export default ContactUs
